import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function Section8CompCompliance() {
  /* Slider */
  // const Section8CompSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 80,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83`,
  //     header: `Section 8 Company Compliance Filing Service In Hosur`,
  //     content: `Online Section 8 Company Compliance Filing with TODAYFILINGS Experts`,
  //     image: "/imgs/registration/iec/iec-slider.png",
  //     alt_tag: "Best Online IE Code Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Section8 Company Compliance`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const Section8CompAboutData = {
    header: `Section 8 Company Compliance`,
    sub_title: `Best Section 8 Company Compliance Service in India Within 7 days`,
    content_paragraph: [
      `All Section 8 Company Companies are required to submit the
      Section 8 Compliance with the Ministry of Corporate Affairs
      in accordance with the Companies Act of 2013. The major
      reason for founding Section 8 Company is to support,
      nurture, and encourage endeavor's in the fields of science,
      art, athletics, charity, business, and so forth. The
      category of Non-Governmental Organizations includes Section
      8 Companies.`,

      // `These businesses appreciate being referred to as "Limited
      // Companies," but the word "Limited" is not put to the end
      // of the company name. In summary, Section 8 businesses seek
      // to support India's under served populations and sectors.`,
    ],
  };

  /* Scroll Nav Data */
  const Section8CompScrollId = [
    {
      id: `#it-compliance`,
      heading: `IT Compliance`,
    },

    {
      id: `#mandatory`,
      heading: `Mandatory Compliances`,
    },
    {
      id: `#documents`,
      heading: `Documents`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
    {
      id: `#event-based-compliance`,
      heading: `Event Based Compliance`,
    },
  ];

  /* Img Content Component Data */
  const Section8CompIcData = {
    id: 'it-compliance',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Section 8 Company Income Tax Compliance',
    image: '/imgs/checklist.png',
    alt_tag: 'Section 8 Company Income Tax Compliance',
    points: [
      {
        content: `Section 8 firms must file form 10A with the Principal
        Commissioner in order to register under Section 12A of
        the Income Tax Act.`,
        icon: true,
      },
      {
        content: `If the business wants to meet the requirements for the
        exemption, it must adhere to Section 11's
        stipulations.`,
        icon: true,
      },
      {
        content: `Companies that violate the law would be subject to
        fines ranging from 10 lakh to 1 crore rupees, company should receive Section 80G approval
        utilizing Form 10B,Penalties for Failure to Obey`,
        icon: true,
      },
      {
        content: `The company risked
        being prohibited from conducting business in India if
        it failed to submit the animal return or if any of the
        other procedures were not followed.`,
        icon: true,
      },
      {
        content: `Each official member of the company who is in default,
        including the directors, would be held accountable and
        may face both incarceration and a fine of up to 25
        lakh rupees.`,
        icon: true,
      },
      {
        content: ` Every person or official in default will be subject to
        action under Area 447 in the event that it is
        discovered that the company's problems were improperly
        directed.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const Section8CompCfData = {
    id: 'mandatory',
    // mt_div: "mt-5",
    // mt_img: "mt-5",
    header: 'List of mandatory Section 8 company compliances',
    content: '',
    body: [
      {
        head: 'Choosing An Auditor',
        points: `Section 8 corporation is required to appoint an
          auditor to annually review and maintain their
          financial records and statements.`,
        icon: true,
      },
      {
        head: 'Arrange a Board Meeting',
        points: `The company's board of directors should meet twice a
          year, with no more than three months or ninety days
          separating each meeting.`,
        icon: true,
      },
      {
        head: 'Filing Income Tax Returns',
        points: `Section 8 companies must submit their IT returns by
          September 30 of the prior fiscal year, at the latest.
          It is necessary for the company to file an income tax
          return in order to provide comprehensive information
          about its earnings. The corporation can benefit from
          tax exemption if it is registered under Sections 12A
          and 80G`,
        icon: true,
      },
      {
        head: 'Financial Return Filing With The RoC',
        points: `To send copies of financial reports and statements,
          utilize the E-form AOC-4. Within a month or 30 days
          after the date the annual general meeting was held, it
          must be filed.`,
        icon: true,
      },
      {
        head: 'Organize Yearly General Meeting',
        points: `This type of company's annual general meeting should
          be held on or before September 30. The attendance of
          all the directors, shareholders, members, and auditors
          is required. All interested members must receive a
          proper notice of the meeting within 21 days.`,
        icon: true,
      },
      {
        head: 'Annual Return Filing with RoC',
        points: `A form called Form MGT-7 is required to be filed with
          the yearly return of a section 8 firm. Within 60 days
          of the date of the annual general meeting, the annual
          return must be submitted. The annual return must be
          filed within sixty days if there is no Annual General
          Meeting held during the year.`,
        icon: true,
      },
    ],
  };
  /*  Content Img Component Data */
  const Section8CompCiData = {
    id: 'documents',
    background: '',
    mt_div: '',
    header: 'Required documents for Section 8 Company Compliance',
    paragraph: `The following list of documentation is required for
  Section 8 Company annual compliances:`,
    points: [
      {
        content: `Company's Articles of Association`,
        icon: true,
      },
      {
        content: `Company's Memorandum of Association`,
        icon: true,
      },
      {
        content: `Digital Signature Certificate, or DSC`,
        icon: true,
      },
      {
        content: `Certificate of Section 8 Company Incorporation`,
        icon: true,
      },
      {
        content: `Income tax filing certificates`,
        icon: true,
      },
      {
        content: `AOC-4 and MGT-7 for filing the compliances`,
        icon: true,
      },
    ],
    image: '/imgs/business/dsc-pvt.png',
    alt_tag: 'Required Documents for Section 8 Company Compliance Registration',
  };
  /*  Content Img Component Data */
  const Section8CompCompCiData = {
    id: 'event-based-compliance',
    background: '',
    mt_div: '',
    header: `Annual Compliances of a Section 8 Company Based on
  Events`,
    paragraph: `The compliances should be event-based, as the name
  suggests, and should relate to specific company
  events. These compliances are regarded as non-periodic
  in character.The Event-Based Compliances For Section 8 Company
  Checklist is as follows:`,
    points: [
      {
        content: `Information about share transactions for the company.`,
        icon: true,
      },
      {
        content: `Distribution of the company's shares.`,
        icon: true,
      },
      {
        content: `Information on the Appointment or Termination of
      Directors and Auditors.`,
        icon: true,
      },
      {
        content: `If any modification in the company’s name and MOA.`,
        icon: true,
      },
      {
        content: `Information on Key Managerial Personnel Appointments.`,
        icon: true,
      },
      {
        content: `The receipts for the share application funds.`,
        icon: true,
      },
      {
        content: `Changes to the organization's structure.`,
        icon: true,
      },
    ],
    image: '/imgs/business/closureup.png',
    alt_tag: 'Annual Compliances of a Section 8 Company Based on Events',
  };

  /*  Slider Images */
  const imgSlider = [
    '/imgs/assited/management.png',
    '/imgs/assited/pre-incorpration.png',
    '/imgs/assited/cfo-management.png',
  ];

  /*  Slider Content Component Data */
  const Section8CompSCData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Benefits of Section 8 Compliance for Companies',
    content: [
      {
        head: '',
        points: `Gives the business more reputation or reliability.`,
        icon: true,
      },
      {
        points: `Safeguards the business against all kinds of legal
      issues.`,
        icon: true,
      },
      {
        points: `Assist the business in avoiding fines and penalties.`,
        icon: true,
      },
      {
        points: `With the goal of establishing confidence with its
      clients.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };
  var FAQ_data = [
    {
      header: "What happens if I don't submit my annual return?",
      body: [
        {
          content: `The penalty for failing to file annual returns is Rs 50,000, with a
          maximum fine of Rs 5 lakh.`,
          icon: false,
        },
      ],
    },

    {
      header:
        'Can non-residents or people from other countries donate to Section 8 Companies?',
      body: [
        {
          content: `A Section 8 Company must put together additional conditions under
          the Foreign Contribution and Regulation Act, 2010, in order to
          accept any funds, contributions, or gifts from abroad or from
          outside India.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is it possible to register as or convert a Society under the Societies Registration Act of 1860 into a Section 8 Company?',
      body: [
        {
          content: `Yes, under Section 8(1) of the Companies Act of 2013, any
          individual or group of individuals may register as a Section 8
          Company by fulfilling the requirements outlined in that section.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Do the Minimum and Maximum Numbers of Directors in a Section 8 Company Have to Meet Any Prescribed Standards?',
      body: [
        {
          content: `The prescription under section 149(1) of Companies Act 2013 as to having Minimum of three
           directors for public limited company and two directors for private limited company and maximum of 
           fifteen directors is not applicable to section 8 company and thus there is no prescription with 
           respect to minimum or maximum directors in a section 8 Company. However, second proviso to section 
           149(1) requires a woman director in prescribed class of companies. Also section 149(3) requires every
            company to have a resident director.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Are there any prescribed criteria with respect to Minimum and Maximum number of directors in a Section 8 Company? ',
      body: [
        {
          content: `It should have a minimum of two directors for a private limited
          company, three directors for a public limited company, and a
          maximum of fifteen directors, according to section 149(1) of the
          Companies Act of 2013, but it is not applicable to section 8
          companies, so there is no prescription regarding the minimum and
          maximum number of directors in a section 8 company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Can a Society registered under the Societies Registration Act, 1860 be registered/converted into Section 8 Company? ',
      body: [
        {
          content: `Yes. Section 8(1) of the Companies Act, 2013 allows person or association of persons to be
           registered as a Section 8 Company on fulfilment of certain conditions and procedure as prescribed therein.`,
          icon: true,
        },
        {
          content: `The term “person” has not been defined in the Companies Act, 2013. Section 2(41) of the General Clauses Act, 
           1897 provides that “person” shall include any Company, or association or body of individuals, whether 
           incorporated or not. Accordingly, a Society registered under the Societies Registration Act, 1860 is a person`,
          icon: true,
        },
        {
          content: `Therefore, Society can be registered/converted as a Section 8 Company.`,
          icon: true,
        },
      ],
    },
    {
      header:
        ' Is there any relaxation in Stamp duty payment on issuance of share certificate by a Section 8 Company?  ',
      body: [
        {
          content: `Stamp duty on issue of share certificates is governed by Indian Stamp Act, 1899 as adapted by 
          respective state or stamp act of respective state, as the case may be. No relaxation of special rate of 
          stamp duty has been provided by any of the state in respect of stamp duty payable on issue of share 
          certificates by Section 8 Company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Can Section 8 Companies receive contributions from overseas or non-residents? ',
      body: [
        {
          content: `There are special requirements to be complied with under the Foreign Contribution and Regulation Act,
           2010 before a Section 8 Company can receive any contributions or donations from overseas/outside India from
            non-residents. The provisions of the said Act are in addition to the provisions under the Companies Act.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Can NRIs /Foreign Nationals be a Director in a Section 8 Company?',
      body: [
        {
          content: `Yes, a NRI or Foreign National can be a Director in a Section 8 Company after obtaining Director 
          Identification Number. However, at least one Director on the Board of Directors must be a Resident India.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the Director Identification Number?',
      body: [
        {
          content: `Director Identification Number is a unique identification number assigned to all existing and 
          proposed Directors of a Company. It is mandatory for all present or proposed Directors to have a Director 
          Identification Number. Director Identification Number never expires and a person can have only one Director 
          Identification Number.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Section 8 Company Compliance'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={Section8CompSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={Section8CompAboutData} />

          <Counter />
          <ScrollNav scroll_data={Section8CompScrollId} />

          <ImgContent item={Section8CompIcData} />

          <ContentForm CFSection_data={Section8CompCfData} />

          <ContentImg CISection_data={Section8CompCiData} />
          <SliderContent ScSection_data={Section8CompSCData} />

          <ContentImg CISection_data={Section8CompCompCiData} />
          <section style={{ marginTop: '100px' }}></section>

          <Cta />
          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
